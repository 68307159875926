import cn from 'classnames'
import Cookies from 'js-cookie'

const cookieName = 'callahan-auth'

export function passwordHash(password : string) {
  return password.split('').reduce((prev, curr) => Math.imul(31, prev) + curr.charCodeAt(0) | 0, 0) + ''
}

export const setSitePassword = ( passwordCandidate: string ) => {
  Cookies.set(cookieName, passwordHash(passwordCandidate));
};

export const checkSitePassword = () => {
  const sitePassword = process.env.SITE_PASSWORD, sessionPassword = Cookies.get(cookieName)

  if ( ! sitePassword ) {
    return true
  }

  if ( ! sessionPassword ) {
    return false
  }

  return ( sessionPassword === passwordHash(sitePassword) );
};

export type ClassName = cn.ArgumentArray[number];
