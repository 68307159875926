import * as React from 'react';
import { useState } from 'react';
import { setSitePassword } from '../lib/utils';
import * as style from '../css/password.module.css';

export default function PasswordProtect() {
  const [password, setPassword] = useState('');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSitePassword(password);
    window.location.reload();

    return e
  };

  return (
    <div className={style.passwordForm}>
      <div>
        <h1>Enter password</h1>

        <form onSubmit={onSubmit}>
          
          <input name="password" type="password" value={password} onChange={event => setPassword(event.target.value)} />

          <input type="submit" value="Enter" />
        </form>
      </div>
    </div>
  );
};
